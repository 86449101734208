<template>
  <base-section
    id="subscribe"
    class="indigo lighten-1 text-center"
    space="56"
    dark
  >
    <v-row class="align-center justify-center">
      <h3 class="text-uppercase">
        Подпишись на нашу рассылку
      </h3>
      <v-responsive
        class="px-4 mx-6"
        width="100%"
        max-width="600"
      >
        <v-text-field
          v-model="email"
          outlined
          dense
          hide-details
          single-line
          label="ваш email"
        >
          <template #append-outer>
            <v-btn
              outlined
              class="ml-n1"
              height="40px"
              style="margin-top: -8px"
            >
              Подписаться
            </v-btn>
          </template>
        </v-text-field>
      </v-responsive>
    </v-row>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionNews',

    provide: {
      heading: { align: 'center' },
    },
    data: () => ({
      email: null,
    }),
  }
</script>
